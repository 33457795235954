// src/components/Sidebar.js
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, CssBaseline, Divider, Box, Typography, Avatar, IconButton } from '@mui/material';
import { BarChart, Assignment, Support, PowerSettingsNew, Settings, TableChart, ListAlt, Person, Group } from '@mui/icons-material';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AlignCenter } from 'lucide-react';

const drawerWidth = 265;  // Increased width

const Sidebar = ({ user })  => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate('/login');
    });
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const getWelcomeMessage = (email) => {
    switch(email) {
      case 'mustafa@fortcorps.com':
        return { name: 'Mustafa', fontSize: '1.1rem' };
      case 'aseel@fortcorps.com':
        return { name: 'Aseel', fontSize: '1.2rem' };
      case 'muhab@fortcorps.com':
        return { name: 'Muhab', fontSize: '1.2rem' };
      case 'zak@fortcorps.com':
        return { name: 'Zak', fontSize: '1.4rem' };
      case 'omar@fortcorps.com':
        return { name: 'Omar', fontSize: '1.3rem' };
      default:
        return { name: 'Mohamed', fontSize: '1rem' };
    }
  };
  
  const welcomeMessage = getWelcomeMessage(user.email);


  const items = [
    { text: 'Projects', icon: <TableChart />, path: '/' },
    { text: 'Tasks', icon: <ListAlt />, path: '/tasks' },
    { text: 'Clients', icon: <Group />, path: '/clients' },
    { text: 'Analytics', icon: <BarChart />, path: '/analytics' }
  ];

  const restrictedUsers = ['mustafa@fortcorps.com', 'aseel@fortcorps.com'];
  const filteredItems = restrictedUsers.includes(user.email) ? items.filter(item => item.text !== 'Projects' && item.text !== 'Clients' && item.text !== 'Analytics') : items;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: 'white', boxShadow: '2px 0 5px rgba(0,0,0,0.1)',overflowX: 'hidden' },
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', padding: '10px 16px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt="User" src="/static/images/avatar/1.jpg" />
            <Box ml={2}>
            <Typography variant="h6" noWrap sx={{ fontSize: welcomeMessage.fontSize, color: 'black' }}>
                Welcome, {welcomeMessage.name}
              </Typography>
            </Box>
          </Box>
          <IconButton>
            <Settings />
          </IconButton>
        </Toolbar>
        <Divider />
        <List>
          {filteredItems.map((item, index) => (
            <ListItem 
              button 
              key={index} 
              sx={{ 
                padding: '10px 16px', 
                '&:hover': { backgroundColor: '#e0f7fa' },
                '&.Mui-selected': { backgroundColor: '#b2ebf2', color: '#00796b' },
                '&.Mui-selected:hover': { backgroundColor: '#80deea' }
              }} 
              onClick={() => handleNavigation(item.path)}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'black', position: 'relative', left: '35px'  }}>{item.icon}</ListItemIcon>
              <ListItemText 
                primary={item.text} 
                primaryTypographyProps={{ 
                  fontSize: '1.4rem', 
                  fontWeight: '500', 
                  color: 'black', 
                  //fontFamily: 'Roboto' 
                  position: 'relative',
                  left: '35px'  // Adjust the value to move left or right
  
                }} 
              />
            </ListItem>
          ))}
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <List>
          <ListItem button sx={{ padding: '10px 16px',position: 'relative' }}>
            <ListItemIcon sx={{ minWidth: 40, color: 'black', position: 'relative', left: '35px' }}><Support /></ListItemIcon>
            <ListItemText 
              primary="Support" 
              primaryTypographyProps={{ 
                fontSize: '1.4rem', 
                fontWeight: '500', 
                color: 'black', 
                //fontFamily: 'Roboto' 
                position: 'relative',
                left: '35px'  // Adjust the value to move left or right

              }} 
            />
          </ListItem>
          <ListItem button sx={{ padding: '10px 16px',position: 'relative' }} onClick={handleSignOut}>
            <ListItemIcon sx={{ minWidth: 40, color: 'black', position: 'relative', left: '35px'  }}><PowerSettingsNew /></ListItemIcon>
            <ListItemText 
              primary="Sign Out" 
              primaryTypographyProps={{ 
                fontSize: '1.4rem', 
                fontWeight: '500', 
                color: 'black', 
                //fontFamily: 'Roboto' 
                position: 'relative',
                left: '35px'  // Adjust the value to move left or right

              }} 
            />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

