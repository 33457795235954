// src/components/Clients.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid
} from '@mui/material';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import EditClient from './EditClient';
import { Card, CardContent, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const Clients = ({ user }) => {
  const [client, setClient] = useState('');
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [clientInfo, setClientInfo] = useState({});
  const db = getFirestore();

  const navigate = useNavigate();


// Redirect if the user's email is mustafa@fortcorps.com
useEffect(() => {
  if (user.email === 'mustafa@fortcorps.com' || user.email === 'aseel@fortcorps.com') {
    navigate('/tasks');  // Redirect to tasks page if the user is not allowed to access Orders
  }
}, [user, navigate]);


  useEffect(() => {
    const fetchClients = async () => {
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      const clientsList = clientsSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().clientName }));
      clientsList.sort((a, b) => a.name.localeCompare(b.name));
      setClients(clientsList);
      if (clientsList.length > 0) {
        setClient(clientsList[0].name);
        setSelectedClientId(clientsList[0].id);
        fetchClientInfo(clientsList[0].id);
      }
    };
    fetchClients();
  }, [db]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (client) {
        const projectsCollection = collection(db, 'projects');
        const projectsSnapshot = await getDocs(projectsCollection);
        const fetchedProjects = projectsSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(project => project.client === client);
        setProjects(fetchedProjects);
      }
    };
    fetchProjects();
  }, [client, db]);

  const fetchClientInfo = async (clientId) => {
    const clientDoc = await getDoc(doc(db, 'clients', clientId));
    if (clientDoc.exists()) {
      setClientInfo(clientDoc.data());
    }
  };

  const handleClientChange = (event) => {
    const selectedClientName = event.target.value;
    const selectedClient = clients.find(c => c.name === selectedClientName);
    setClient(selectedClientName);
    setSelectedClientId(selectedClient.id);
    fetchClientInfo(selectedClient.id);
  };

  const handleProjectRefClick = (projectId) => {
    // Handle project reference click
  };

  const handleEditClientClick = () => {
    if (selectedClientId) {
      setOpenEdit(true);
    }
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setSelectedClientId(null);
  };

  return (
    <Container>
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Clients
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="client-select-label">Client</InputLabel>
          <Select
            labelId="client-select-label"
            id="client-select"
            value={client}
            label="Client"
            onChange={handleClientChange}
          >
            {clients.map((clientObj, index) => (
              <MenuItem key={clientObj.id} value={clientObj.name}>
                {clientObj.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {client && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditClientClick}
            >
              Edit Client
            </Button>
          </Box>
        )}
       {client && (
  <Card sx={{ mt: 2, mb: 2, p: 2, boxShadow: 3 }}>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Client Information
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Grid container spacing={2}>
        {clientInfo.addressLine1 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Address Line 1:</Typography>
            <Typography>{clientInfo.addressLine1}</Typography>
          </Grid>
        )}
        {clientInfo.addressLine2 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Address Line 2:</Typography>
            <Typography>{clientInfo.addressLine2}</Typography>
          </Grid>
        )}
        {clientInfo.addressLine3 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Address Line 3:</Typography>
            <Typography>{clientInfo.addressLine3}</Typography>
          </Grid>
        )}
        {clientInfo.city && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">City:</Typography>
            <Typography>{clientInfo.city}</Typography>
          </Grid>
        )}
        {clientInfo.stateCounty && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">State/County:</Typography>
            <Typography>{clientInfo.stateCounty}</Typography>
          </Grid>
        )}
        {clientInfo.country && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Country:</Typography>
            <Typography>{clientInfo.country}</Typography>
          </Grid>
        )}
        {clientInfo.postCode && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Post Code:</Typography>
            <Typography>{clientInfo.postCode}</Typography>
          </Grid>
        )}
        {clientInfo.telephoneNumber && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Telephone Number:</Typography>
            <Typography>{clientInfo.telephoneNumber}</Typography>
          </Grid>
        )}
        {clientInfo.email && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Email:</Typography>
            <Typography>{clientInfo.email}</Typography>
          </Grid>
        )}
        {clientInfo.pointOfContacts && clientInfo.pointOfContacts.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Points of Contact:</Typography>
            {clientInfo.pointOfContacts.map((poc, index) => (
              <Typography key={index}>- {poc}</Typography>
            ))}
          </Grid>
        )}
        {clientInfo.companyReference && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Company Reference:</Typography>
            <Typography>{clientInfo.companyReference}</Typography>
          </Grid>
        )}
        {clientInfo.otherInfo && (
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textSecondary">Other Info:</Typography>
            <Typography>{clientInfo.otherInfo}</Typography>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
)}

        {projects.length > 0 && (
          <TableContainer component={Paper} sx={{ mt: 4 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Project Ref</TableCell>
                  <TableCell>Project Manager</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Status</TableCell>
                  {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com' || user.email === 'muhab@fortcorps.com') && (
                    <>
                      <TableCell>Income</TableCell>
                      <TableCell>VAT</TableCell>
                      <TableCell>Amount Inc VAT</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project, index) => (
                  <TableRow key={project.id} style={{
                      backgroundColor: project.projectStatus === 'Overdue' ? 'lightcoral' :
                        project.projectStatus === 'Attention' ? 'lightgoldenrodyellow' :
                          project.projectStatus === 'In Progress' ? 'lightgreen' :
                            project.projectStatus === 'Completed' ? 'lightblue' :
                              project.projectStatus === 'To be invoiced' ? 'lightpink' :
                                project.projectStatus === 'Invoiced' ? '#CBC3E3' :
                                  index % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.04)'
                    }}>
                    <TableCell>
                      <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => handleProjectRefClick(project.id)}
                      >
                        {project.projectRef}
                      </span>
                    </TableCell>
                    <TableCell>{project.projectManager}</TableCell>
                    <TableCell>{project.cost}</TableCell>
                    <TableCell>{project.projectStatus}</TableCell>
                    {(user.email === 'mtabib86@yahoo.com' || user.email === 'zak@fortcorps.com' || user.email === 'omar@fortcorps.com' || user.email === 'muhab@fortcorps.com') && (
                      <>
                        <TableCell>{project.income}</TableCell>
                        <TableCell>{project.vat}%</TableCell>
                        <TableCell>{project.amountIncVat}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Dialog open={openEdit} onClose={handleCloseEdit} maxWidth="md" fullWidth>
        <DialogTitle>Edit Client</DialogTitle>
        <DialogContent>
          {selectedClientId && <EditClient clientId={selectedClientId} onClose={handleCloseEdit} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Clients;
