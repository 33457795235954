import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Divider,
} from "@mui/material";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { Pie, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";
import { writeFile, utils } from "xlsx";


// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);


const Analytics = () => {
  const [selectedClient, setSelectedClient] = useState("All");
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const [selectedYear, setSelectedYear] = useState("All");
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const availableYears = [
    ...new Set(
      allData
        .flatMap((project) =>
          (project.tasks || []).map((task) => {
            if (!task.orderAccepted) return null; // Skip if orderAccepted is missing
            const parsedDate = new Date(task.orderAccepted);
            if (isNaN(parsedDate.getTime())) return null; // Skip invalid dates
            const year = parsedDate.getFullYear();
            if (year < 2000 || year > new Date().getFullYear() + 1) return null; // Skip unrealistic years
            return year; // Return valid year
          })
        )
        .filter((year) => year !== null) // Remove null values
    ),
  ].sort((a, b) => a - b); // Sort years in ascending order
  
  
  const exportToExcel = () => {
    // Create filter information
    const filterInfo = [
      { Filter: "Applied Filters" },
      { Filter: `Year: ${selectedYear}` },
      { Filter: `Client: ${selectedClient}` },
      { Filter: `Country: ${selectedCountry}` },
      { Filter: `Type: ${selectedType}` },
      { Filter: "" },  // Empty row for spacing
      { Filter: "" }  // Empty row for spacing
    ];

    // Create the monthly data rows
    const monthlyRows = monthlyData.map((month, index) => ({
      Month: months[index],
      Income: month.income?.toFixed(2) || "-",
      VAT: month.vat?.toFixed(2) || "-",
      IncomeIncVAT: month.incomeIncVat?.toFixed(2) || "-",
      MonthlyCost: month.monthlyCost?.toFixed(2) || "-",
    }));

    // Create a worksheet with filter information first
    const worksheet = utils.json_to_sheet(filterInfo, { header: ["Filter"] });

    // Calculate the start row for monthly data (after filter info + spacing)
    const dataStartRow = filterInfo.length + 1;

    // Add the monthly data below the filter information
    utils.sheet_add_json(worksheet, monthlyRows, {
      origin: `A${dataStartRow}`,
      skipHeader: false
    });

    // Create a workbook and add the worksheet
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Financial Data");

    // Get current date for filename
    const date = new Date();
    const dateStr = date.toISOString().split('T')[0];

    // Export the workbook with date in filename
    writeFile(workbook, `Financial_Data_${dateStr}.xlsx`);
  };

  // Helper to ensure numeric values
  const ensureNumber = (value) => {
    const num = parseFloat(value);
    return isNaN(num) ? 0 : num;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectsCollection = collection(db, "projects");
        const snapshot = await getDocs(projectsCollection);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllData(data);
        setFilteredData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filtered = allData;

    if (selectedYear !== "All") {
      const targetYear = selectedYear.toString();
      filtered = filtered.filter(project => 
        project.tasks?.some(task => {
          if (!task.orderAccepted) return false;
          const date = new Date(task.orderAccepted);
          return !isNaN(date) && date.getFullYear().toString() === targetYear;
        })
      );
    }
    
    
    
    
    

    if (selectedClient !== "All") {
      filtered = filtered.filter((item) => item.client === selectedClient);
    }

    if (selectedCountry !== "All") {
      filtered = filtered.filter((item) =>
        (item.tasks || []).some((task) => task.country === selectedCountry)
      );
    }

    if (selectedType !== "All") {
      filtered = filtered.filter((item) =>
        (item.tasks || []).some((task) => task.task === selectedType)
      );
    }

    setFilteredData(filtered);
  }, [selectedClient, selectedCountry, selectedType, selectedYear, allData]);


  useEffect(() => {
    const monthlyTotals = Array(12)
      .fill(null)
      .map(() => ({
        income: 0,
        vat: 0,
        incomeIncVat: 0,
        monthlyCost: 0,
      }));

    filteredData.forEach((project) => {
      if (!project.tasks) return;

      project.tasks.forEach((task) => {
        if (!task.orderAccepted || !task.income) {
          console.warn(`Skipping invalid task`, task);
          return;
        }

        const monthIndex = new Date(task.orderAccepted).getMonth();
        const income = ensureNumber(task.income);
        const vatRate = ensureNumber(task.vat);
        const cost = ensureNumber(task.cost);

        const vat = (income * vatRate) / 100;
        const incomeIncVat = income + vat;

        monthlyTotals[monthIndex].income += income;
        monthlyTotals[monthIndex].vat += vat;
        monthlyTotals[monthIndex].incomeIncVat += incomeIncVat;
        monthlyTotals[monthIndex].monthlyCost += cost;
      });
    });

    setMonthlyData(monthlyTotals);
  }, [filteredData]);

  // Summary Statistics
  const totalIncome = monthlyData.reduce((sum, month) => sum + month.income, 0);
  const totalIncomeIncVat = monthlyData.reduce(
    (sum, month) => sum + month.incomeIncVat,
    0
  );
  const totalMonthlyCost = monthlyData.reduce(
    (sum, month) => sum + month.monthlyCost,
    0
  );
  const totalGrossProfit = totalIncomeIncVat - totalMonthlyCost;
  const totalProfitMargin = totalIncomeIncVat
    ? (totalGrossProfit / totalIncomeIncVat) * 100
    : 0;
  const monthsWithData = monthlyData.filter((month) => month.income > 0).length;
  const monthlyAvgIncome = monthsWithData
    ? totalIncome / monthsWithData
    : 0;


    const prepareChartData = (data, groupBy) => {
      const groupedData = {};
    
      data.forEach((project) => {
        // If grouping by 'client', get it from the project level
        if (groupBy === "client") {
          const client = project.client || "Unknown";
          const income = (project.tasks || []).reduce(
            (sum, task) => sum + ensureNumber(task.income),
            0
          );
          groupedData[client] = (groupedData[client] || 0) + income;
        } else {
          // Otherwise, process tasks
          (project.tasks || []).forEach((task) => {
            const key = task[groupBy] || "Unknown";
            const income = ensureNumber(task.income);
            groupedData[key] = (groupedData[key] || 0) + income;
          });
        }
      });
    
      return {
        labels: Object.keys(groupedData),
        datasets: [
          {
            label: "Income",
            data: Object.values(groupedData),
            backgroundColor: [
              "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"
            ],
            hoverBackgroundColor: [
              "#FF6384AA", "#36A2EBAA", "#FFCE56AA", "#4BC0C0AA", "#9966FFAA", "#FF9F40AA"
            ],
          },
        ],
      };
    };
    


  return (
    <Box sx={{ padding: "0px", maxWidth: '100%' }}>
      <Box sx={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
      <Box>
  <Typography variant="subtitle1">Year</Typography>
  <Select
    value={selectedYear}
    onChange={(e) => setSelectedYear(e.target.value)}
    fullWidth
  >
    <MenuItem value="All">All</MenuItem>
    {availableYears.map((year, index) => (
      <MenuItem key={index} value={year}>
        {year}
      </MenuItem>
    ))}
  </Select>
</Box>


        <Box>
          <Typography variant="subtitle1">Client</Typography>
          <Select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            fullWidth
          >
            <MenuItem value="All">All</MenuItem>
            {[...new Set(allData.map((item) => item.client || "Unknown"))].map(
              (client, index) => (
                <MenuItem key={index} value={client}>
                  {client}
                </MenuItem>
              )
            )}
          </Select>
        </Box>
        <Box>
          <Typography variant="subtitle1">Country</Typography>
          <Select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            fullWidth
          >
            <MenuItem value="All">All</MenuItem>
            {[
              ...new Set(
                allData
                  .flatMap((item) => (item.tasks || []).map((task) => task.country || "Unknown"))
                  .filter((country) => country)
              ),
            ].map((country, index) => (
              <MenuItem key={index} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          <Typography variant="subtitle1">Type</Typography>
          <Select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            fullWidth
          >
            <MenuItem value="All">All</MenuItem>
            {[
              ...new Set(
                allData
                  .flatMap((item) => (item.tasks || []).map((task) => task.task || "Unknown"))
                  .filter((type) => type)
              ),
            ].map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Box>

        
      </Box>

      <Box sx={{ position: 'relative', width: '100%', mb: 2 }}>

      <Box sx={{ position: 'absolute', right: 0, top: -68, zIndex: 1 }}>
          <button 
            onClick={exportToExcel} 
            style={{ 
              padding: "10px", 
              background: "#4caf50", 
              color: "white", 
              border: "none", 
              borderRadius: "4px", 
              cursor: "pointer"
            }}
          >
            Export to Excel
          </button>
        </Box>


      <Paper elevation={3} sx={{ width: '100%', overflowX: 'auto', marginX: 'auto' }}>
        <Table sx={{ minWidth: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Income</TableCell>
              <TableCell>VAT</TableCell>
              <TableCell>Income Inc VAT</TableCell>
              <TableCell>Monthly Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
      {months.map((month, index) => (
        <TableRow key={index}>
          <TableCell>{month}</TableCell>
          <TableCell>{monthlyData[index]?.income.toFixed(2) || "-"}</TableCell>
          <TableCell>{monthlyData[index]?.vat.toFixed(2) || "-"}</TableCell>
          <TableCell>{monthlyData[index]?.incomeIncVat.toFixed(2) || "-"}</TableCell>
          <TableCell>{monthlyData[index]?.monthlyCost.toFixed(2) || "-"}</TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell>
          <strong>Total</strong>
        </TableCell>
        <TableCell>{totalIncome.toFixed(2)}</TableCell>
        <TableCell>{monthlyData.reduce((sum, month) => sum + month.vat, 0).toFixed(2)}</TableCell>
        <TableCell>{totalIncomeIncVat.toFixed(2)}</TableCell>
        <TableCell>{totalMonthlyCost.toFixed(2)}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
</Paper>
</Box>

      <Divider sx={{ my: 3 }} />

      <Box>
        <Typography variant="h6">Summary Statistics</Typography>
        <Typography>Monthly Gross Profit: {totalGrossProfit.toFixed(2)}</Typography>
        <Typography>
          Monthly Profit Margin (%): {totalProfitMargin.toFixed(2)}%
        </Typography>
        <Typography>
          Monthly Average Income: {monthlyAvgIncome.toFixed(2)}
        </Typography>
        <Typography>Total Gross Profit: {totalGrossProfit.toFixed(2)}</Typography>
        <Typography>
          Total Profit Margin (%): {totalProfitMargin.toFixed(2)}%
        </Typography>
      </Box>
      {/* Charts Section */}
<Divider sx={{ my: 3 }} />
<Box sx={{ mt: 4 }}>
  <Typography variant="h6" sx={{ mb: 2 }}>
    Charts
  </Typography>

  {/* Percentage of Income by Type */}
  <Box sx={{ mb: 4 }}>
    <Typography variant="subtitle1">Percentage of Income by Type</Typography>
    <Pie 
  data={prepareChartData(filteredData, "task")} 
  options={{
    maintainAspectRatio: true,
    aspectRatio: 1.75, // Adjust this value for a smaller/larger chart
  }}
/>
  </Box>

  {/* Income by Client */}
  <Box sx={{ mb: 4 }}>
    <Typography variant="subtitle1">Income by Client</Typography>
    <Bar data={prepareChartData(filteredData, "client")} />
  </Box>

  {/* Income by Country */}
  <Box sx={{ mb: 4 }}>
    <Typography variant="subtitle1">Income by Country</Typography>
    <Bar data={prepareChartData(filteredData, "country")} />
  </Box>
</Box>

    </Box>
    
  );
  
};

export default Analytics;
